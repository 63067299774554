
import './App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_IAHCHImhf53VLh83jsAIxjtpUgAf8-A",
  authDomain: "hannah-trogdon.firebaseapp.com",
  projectId: "hannah-trogdon",
  storageBucket: "hannah-trogdon.appspot.com",
  messagingSenderId: "351170033944",
  appId: "1:351170033944:web:a497b7b3087a69751dc66d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <h1>Put on your yarmulke.</h1>
      <img src="./assets/ht.jpg" alt="dog trick" className='crosby'/>
    </div>
  );
}

export default App;
